import React from 'react';
import { Link } from 'gatsby';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import image from '../images/logo.png';

const Header = () => {
  return (
    <Navbar
      color="#cccccc"
      collapseOnSelect
      expand="lg"
      // bg="primary"
      variant="light"
    >
      <Container>
        <Link to="/" className="navbar-brand">
          <img src={image} alt="" />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Link to="/" className="nav-link">
              Home
            </Link>
            <Link to="/licensingcenters/" className="nav-link">
              Licensing centers
            </Link>
            <NavDropdown title="License Info" id="collasible-nav-dropdown">
              <Link to="/howtoapply/" className="dropdown-item">
                How To Apply
              </Link>
              <Link to="/verify/" className="dropdown-item">
                Verify Licence
              </Link>
              <Link to="/tracklicence/" className="dropdown-item">
                Track Licence
              </Link>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
