import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Footer = () => {
  return (
    <section className="bg-dark text-white py-5">
      <Container>
        <Row>
          <Col md={3}>
            <h6 className="font-weight-bold">ABOUT US</h6>
            <p className="text-small">
              riving License Issuance Management System (DLIMS) Phase-II
              automates the processes for driving license issuance, renewal and
              upgrades. These activities are carried out throughout the province
              of Punjab. This system provides quick processing service to public
              and up to date statistics to the authorities by using state of the
              art technology and equipment. The system redefines the issuance
              process of all types of licenses by using a centralized network.
            </p>
          </Col>
          <Col md={3}>
            <h6 className="font-weight-bold">CUSTOMER CARE STANDARDS</h6>
            <p className="text-small">
              The customer care standards will be enhanced in such a way that
              the applicant will only have to visit the licensing testing center
              and upon passing the test, the applicant will get internationally
              renowned Secured Card based driving license. In order to have a
              self-sustainable model to continue giving best service delivery to
              the general public, revolving fund will be created for inward and
              outward transactions.
            </p>
          </Col>
          <Col md={3}>
            <h3 className="h6 font-weight-bold">KEY FEATURES</h3>
            <p className="text-small">
              Centralized Issuance and Management of Driving Licenses
            </p>
            <p className="text-small">
              Centralized Database of Licensing System of whole Gujranwala
            </p>
            <p className="text-small">
              Centralized Driving License Printing Facilit
            </p>
            <p className="text-small">
              Delivery of Issued Licenses through courier and basing on that
              verification of the Applicant can be made easy
            </p>
          </Col>
          <Col md={3}>
            <h2 className="h6 font-weight-bold">CONTACT US</h2>
            <p className="text-small">Phone Number: +92.42.35777268</p>
            <p className="text-small">Email:complaint.dlims@pitb.gov.pk</p>
          </Col>
        </Row>
        <div style={{ width: '100%', height: '2px', color: '#ffffff' }}></div>
        <p className="text-center text-small">
          Coptright &copy; {new Date().getFullYear()} all right reserved by
          DlIMS Gurjranwala
        </p>
      </Container>
    </section>
  );
};

export default Footer;
